<template>
  <div :class="$style.title">
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">距离上边间隔</div>
      <div v-if="objForm">
        <a-input v-model="objForm.distance" @change="input1" placeholder="推荐20" />
      </div>
    </div>
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">标题内容</div>
      <div v-if="objForm">
        <a-input v-model="objForm.items[0].name" @change="input1" placeholder="请输入标题" />
      </div>
    </div>
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">描述内容</div>
      <div v-if="objForm">
        <a-input v-model="objForm.items[0].enName" @change="input1" placeholder="请输入描述" />
      </div>
    </div>
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">更多内容</div>
      <div v-if="objForm">
        <a-input v-model="objForm.items[0].moreText" @change="input1" placeholder="请输入更多内容" />
      </div>
    </div>
    <div :class="$style.title__block">
      <!-- 信息回显 -->
        <resourceInfo faName="title" @fatherSelectItem="fatherSelectItem" :item="objForm?objForm.items[0]:''"></resourceInfo>
    </div>
    <div style="display:none">{{tolist}}</div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import resourceInfo from './resourceInfo'
const { mapState, mapActions } = Vuex.createNamespacedHelpers('allocation')
export default {
  components: {
    resourceInfo
  },
  data () {
    return {
      objForm: '',
      urlList: []
    }
  },
  computed: {
    ...mapState([
      'endList',
      'selectIndex'
    ]),
    // eslint-disable-next-line vue/return-in-computed-property
    tolist () {
      this.getLists()
    }
  },
  created () {
    this.urlList = []
    this.getComponentLinks()
  },
  methods: {
    ...mapActions([
      'setChangeList'
    ]),
    getLists () {
      if (this.endList[this.selectIndex]) {
        this.objForm = this.$utils.clone(this.endList[this.selectIndex])
      } else {
        this.objForm = ''
      }
      console.log('??????', this.objForm)
    },
    input1 (e) {
      console.log(e.target.value)
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    async getComponentLinks () {
      const res = await this.$store.dispatch('http', {
        api: 'componentLinks'
      })
      console.log('链接列表', res)
      this.urlList = this.$utils.clone(res)
      this.urlList.push({ name: '自定义链接', code: '' })
    },
    fatherSelectItem (item) {
      this.objForm.items[0] = { ...this.objForm.items[0], ...item }
      console.log('最终结果', this.objForm.items[0])
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    }
  },
  destroyed () {
    console.log('哈哈哈哈哈好')
  }
}
</script>

<style lang="scss" module>
  .title{
    width: 100%;
    padding: 20px 20px 0 30px;
    box-sizing: border-box;
    &__block{
      width: 100%;
      margin-bottom: 20px;
      &__title{
        margin-bottom: 20px;
      }
    }
  }
</style>
