<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-12-09 17:06:04
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-10 15:25:43
-->
<template>
  <a-modal :class="$style.container" v-model="isShow" :title="info.name" @ok="determine" @cancel="cancel" class="selectModle">
    <a-form ref="form" :class="$style.form__ct" class="al-form" layout="inline" :model="form">
      <a-row type="flex" justify="space-between">
        <a-form-item label="更多">
          <a-input v-model="moreText"
              style="width: 180px"
              placeholder="10个字以内，可不填" />
        </a-form-item>
        <a-form-item label="appid">
          <a-input v-model="identifier"
              style="width: 180px"
              placeholder="10个字以内，可不填" />
        </a-form-item>
        <a-form-item label="链接地址">
          <a-input v-model="linkUrl"
              style="width: 180px"
              placeholder="10个字以内，可不填" />
        </a-form-item>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      info: {}, // 对象信息
      form: {
        code: '',
        goyVirtualIds: []
      },
      moreText: '',
      identifier: '',
      linkUrl: '',
      newForm: {},
      paiName: '', // 接口名
      dataList: [],
      selectItem: '',
      replaceFields: { // 树替换属性
        children: 'child',
        title: 'name',
        key: 'id'
      }
    }
  },
  watch: {
  },
  methods: {
    determine (e) {
      this.$emit('selectFun', { name: '小程序', intent: 'open', linkUrl: this.linkUrl, moreText: this.moreText, identifier: this.identifier, resourceType: 'xcx' })
      this.cancel()
      this.isShow = false
    },
    cancel () {
      console.log('呜呜呜呜呜')
      this.selectItem = ''
      this.form = {
        code: ''
      }
    }
  }
}
</script>

<style>
.selectModle .ant-modal-body{
  min-height: 300px;
}
</style>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
