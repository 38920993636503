<template>
  <div :class="$style.urlPage">
    <a-dropdown>
      <a>请选择要选择的链接</a>
      <a-menu slot="overlay">
        <a-menu-item v-for="(listItem,listIndex) in urlList" @click="clickItem(listItem)" :key="listIndex">
          <a>{{listItem.name}}</a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <selectModle ref="selectDialog" @selectFun="selectFun"></selectModle>
    <fillXcx ref="addXcx" @selectFun="selectFun"></fillXcx>
    <contentSelect ref="addContent" @addContent="addContentBack"></contentSelect>
    <goodsSelect ref="addGoods" @addGoods="addGoodsBack"></goodsSelect>
    <brandSelect ref="addBrand" @addBrand="addBrandBack"></brandSelect>
    <brandTypeSelect ref="addBrandType" @addBrandType="addBrandTypeBack"></brandTypeSelect>
    <goodsTypeSelect ref="addGoodsType" @addGoodsType="addGoodsTypeBack"></goodsTypeSelect>
    <contentTypeSelect ref="addContentPage" @addContentPage="addContentPageBack"></contentTypeSelect>
    <customLink ref="addCustomLink" @addCustomLink="addCustomLinkBack"></customLink>
  </div>
</template>

<script>
import selectModle from './selectModle'
import contentSelect from '../resource/contentSelect'
import goodsSelect from '../resource/goodsSelect'
import brandSelect from '../resource/brandSelect'
import brandTypeSelect from '../resource/brandTypeSelect'
import goodsTypeSelect from '../resource/goodsTypeSelect'
import contentTypeSelect from '../resource/contentTypeSelect'
import fillXcx from '../resource/fillXcx'
import customLink from '../resource/customLink'
export default {
  props: ['urlList', 'fatherSelectItem', 'showIndex'],
  components: {
    selectModle,
    goodsSelect,
    brandSelect,
    brandTypeSelect,
    fillXcx,
    customLink,
    contentSelect,
    goodsTypeSelect,
    contentTypeSelect
  },
  data () {
    return {
      showStatus: false,
      selectItem: ''
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    copyItem () {
      console.log('监听的数据', this.fatherSelectItem)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectItem = this.fatherSelectItem
    }
  },
  methods: {
    addGoods (index) {
      this.$refs.addGoods.visible = true
      this.$refs.addGoods.index = index
    },
    addContent (index) {
      this.$refs.addContent.visible = true
      this.$refs.addContent.index = index
    },
    addBrand (index) {
      this.$refs.addBrand.visible = true
      this.$refs.addBrand.index = index
    },
    addContentBack (app) {
      console.log(app)
      console.log(app, 'back12341234')
      let item = {}
      item.identifier = app.id
      // item.imageUrl = app.cmsExtData[0].imageUrl
      item.cmsExtData = [{
        imageUrl: app.imageUrl,
        author: app.author,
        title: app.title
      }]
      item.imageUrl = app.imageUrl
      item.name = app.title
      item.intent = 'open'
      item.resourceType = 'content'
      this.selectFun(item)
    },
    addGoodsBack (app) {
      console.log(app, 'back12341234')
      let item = {}
      item.identifier = app.productId
      item.imageUrl = app.imgUrl
      item.name = app.goodsName
      item.price = app.price
      item.intent = 'open'
      item.resourceType = 'sku'
      this.selectFun(item)
    },
    addBrandBack (app) {
      console.log(app, 'back')
      let item = {}
      item.identifier = app.id
      item.cmsExtData = app.cmsExtData
      item.imageUrl = app.cmsExtData[0].iconUrl
      item.name = app.name
      item.intent = 'open'
      item.resourceType = 'brand'
      this.selectFun(item)
    },
    addBrandTypeBack (app) {
      console.log(app, 'back')
      let item = {}
      item.identifier = app.id
      item.name = app.name
      item.intent = 'open'
      item.resourceType = 'brandType'
      item.cmsExtData = app.cmsExtData
      this.selectFun(item)
    },
    addGoodsTypeBack (app) {
      console.log(app, 'back')
      let item = {}
      item.identifier = app.id
      item.name = app.name
      item.intent = 'open'
      item.resourceType = 'goodsType'
      item.cmsExtData = app.cmsExtData
      this.selectFun(item)
    },
    addContentPageBack (app) {
      console.log(app, 'back')
      let item = {}
      item.identifier = app.id
      item.name = app.name
      item.intent = 'open'
      item.resourceType = app.resourceType
      item.cmsExtData = app.cmsExtData
      this.selectFun(item)
    },
    addCustomLinkBack (app) {
      console.log(app, 'back')
      let item = {}
      item.identifier = app.id
      item.imageUrl = app.imgUrl
      item.name = app.name
      item.intent = 'H5'
      item.resourceType = 'H5'
      this.selectFun(item)
    },
    enter () {
      this.showStatus = true
    },
    leave () {
      this.showStatus = false
    },
    // 标记要改的地方
    clickItem (item) {
      console.log(item, '点击了何种资源')
      switch (item.code) {
        case 'page':
          this.showSelectPage(item)
          break
        case 'xcx':
          this.showFillXcc(item)
          break
        case 'brand':
          this.showSelectBrand(item)
          break
        case 'content':
          this.showSelectContent(item)
          break
        case 'sku':
          this.showSelectGoods(item)
          break
        case 'h5':
          this.showCustomH5(item)
          break
        case 'brandType':
          this.showSelectBrandType(item)
          break
        case 'goodsType':
          this.showSelectGoodsType(item)
          break
        case 'scene':
          this.showSelectContentPage(item)
          break
        case 'navigation':
          this.showSelectContentPage(item)
          break
        default:
          this.showFillLink(item)
          break
      }
    },
    showCustomH5 (item) {
      console.log(item, 'h5')
      this.$refs.addCustomLink.info = this.$utils.clone(item)
      this.$refs.addCustomLink.isShow = true
    },
    showSelectPage (item) {
      this.$refs.selectDialog.info = this.$utils.clone(item)
      this.$refs.selectDialog.isShow = true
    },
    showFillXcc (item) {
      this.$refs.addXcx.info = this.$utils.clone(item)
      this.$refs.addXcx.isShow = true
    },
    showFillLink (item) {
      console.log(item.code)
    },
    showSelectContent (item) {
      this.addContent(item)
    },
    showSelectGoods (item) {
      this.addGoods(item)
    },
    showSelectBrand (item) {
      this.$refs.addBrand.visible = true
      this.$refs.addBrand.iteminfo = item
    },
    showSelectBrandType (item) {
      this.$refs.addBrandType.visible = true
      this.$refs.addBrandType.iteminfo = item
    },
    showSelectGoodsType (item) {
      this.$refs.addGoodsType.visible = true
      this.$refs.addGoodsType.iteminfo = item
    },
    showSelectContentPage (item) {
      console.log(111111111111111111111111)
      this.$refs.addContentPage.visible = true
      this.$refs.addContentPage.iteminfo = item
    },
    selectFun (item) {
      console.log(this.showIndex, '带回来的数据', item)
      this.selectItem = this.$utils.clone(item)
      if (this.showIndex !== undefined) {
        item.showIndex = this.showIndex
        console.log('+++++', item)
        this.$emit('fatherSelectItem', item)
      } else {
        this.$emit('fatherSelectItem', item)
      }
    }
  }
}
</script>

<style lang="scss" module>
  .urlPage{
    width: 180px;
    // padding-bottom: 4px;
    position: relative;
    &__title{
      color: blue;
      cursor: pointer;
    }
    &__block{
      width: 140px;
      background: #fff;
      position: absolute;
      z-index: 10;
      bottom: -256px;
      box-shadow:0px 0px 4px #999;
      &__item{
        width: 100%;
        padding: 6px 10px;
        box-sizing: border-box;
        margin-bottom: 4px;
        cursor: pointer;
      }
    }
  }
</style>
