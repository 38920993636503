<template>
  <a-modal :class="$style.container" v-model="isShow" :title="info.name" @ok="determine" @cancel="cancel" class="selectModle">
    <a-form ref="form" :class="$style.form__ct" class="al-form" layout="inline" :model="form">
      <span>
        <a-form-item :label="'请选择'+info.name+':'">
          <a-select
            show-search
            v-model="form.thr"
            placeholder="请选择"
            style="width: 200px"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="selectChange">
            <a-select-option v-for="(items,indexs) in dataList" :key="indexs" :value="items.nameId">
              {{ items.name }}{{items.mobile?'('+items.mobile+')':''}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </span>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      info: {}, // 对象信息
      form: {
        code: '',
        goyVirtualIds: []
      },
      moreText: '',
      identifier: '',
      linkUrl: '',
      newForm: {},
      paiName: '', // 接口名
      dataList: [],
      selectItem: '',
      replaceFields: { // 树替换属性
        children: 'child',
        title: 'name',
        key: 'id'
      }
    }
  },
  watch: {
    moreText (val) {
      this.selectItem.moreText = val
    },
    identifier (val) {
      this.selectItem.identifier = val
    },
    linkUrl (val) {
      this.selectItem.linkUrl = val
    },
    isShow (val) {
      if (val) {
        if (this.info.code === 'page') {
          this.paiName = 'getManagePage'
          this.newForm = {
            name: this.form.code,
            issue: 1
          }
          this.getDataList()
        } else if (this.info.code === 'goodsTypeList') {
          this.paiName = 'goyVirtualList'
          this.getDataList()
        } else if (this.info.code === 'xcx') {
          console.log(this.selectItem, 'this.selectItem')
          this.selectItem = {
            identifier: this.identifier,
            moreText: this.moreText,
            linkUrl: this.linkUrl,
            itemType: this.info.value,
            nameCategory: this.info.name,
            title: this.info.name,
            codeCategory: this.info.code
          }
        }
      }
    }
  },
  methods: {
    handleSearch (value) {
      console.log('搜索的', value)
      this.getDataList(value)
    },
    selectChange (value) {
      console.log('选中的', value)
      this.dataList.map(item => {
        if (item.nameId === value) {
          console.log(item, '来看看这里')
          this.selectItem = { name: item.name, identifier: value, intent: 'load', resourceType: this.info.code, componentName: 'tab' }
        }
      })
    },
    checkFun (val) {
      if (val) {
        if (this.info.code === 'page') {
          this.paiName = 'getManagePage'
          this.newForm = {
            name: val,
            issue: 1
          }
        } else if (this.info.code === 'goodsTypeList') {
          this.paiName = 'goyVirtualList'
        }
      }
    },
    async getDataList (val) {
      this.checkFun(val)
      const params = Object.assign({
        pageNo: 1,
        pageSize: 10
      }, this.newForm)
      console.log('>>>>>>', this.newForm)
      const res = await this.$store.dispatch('http', {
        api: this.paiName,
        params
      })
      console.log('数据列表', res)
      console.log(this.info.code)
      let list1 = []
      if (res.records) {
        list1 = this.$utils.clone(res.records)
      } else {
        list1 = this.$utils.clone(res)
      }
      if (this.info.code === 'hospital') {
        list1.map(item => {
          item.name = item.hospitalName
          item.nameId = item.hospitalId
        })
      } else if (this.info.code === 'page') {
        list1.map(item => {
          item.nameId = item.id
        })
      } else if (this.info.code === 'doctor') {
        list1.map(item => {
          item.name = item.realName
          item.nameId = item.id
        })
      }
      this.dataList = list1
      console.log(this.dataList)
    },
    determine (e) {
      console.log(this.selectItem, this.info.code, '提交了 在这里修改参数')
      if (this.info.code) {
        if (this.selectItem) {
          this.$emit('selectFun', this.selectItem)
        } else if (this.info.code === 'goodsSearchList') {
          this.$emit('selectFun', { title: this.info.name, itemType: 'client', nameCategory: this.info.name, codeCategory: this.info.code, identifier: this.form.code })
        } else if (this.info.code === 'goodsTypeList') {
          console.log('这里先放一放')
        }
      } else {
        this.$emit('selectFun', { title: '外部链接地址', itemType: 'H5', linkUrl: this.form.code, codeCategory: '', nameCategory: '' })
      }

      this.cancel()
      this.isShow = false
    },
    cancel () {
      console.log('呜呜呜呜呜')
      this.selectItem = ''
      this.form = {
        code: ''
      }
    }
  }
}
</script>

<style>
.selectModle .ant-modal-body{
  min-height: 300px;
}
</style>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
