<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-12-10 16:11:56
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-10 17:03:12
-->
<template>
  <a-modal :class="$style.container" v-model="isShow" :title="info.name" @ok="determine" @cancel="cancel" class="selectModle">
    <a-form ref="form" :class="$style.form__ct" class="al-form" layout="inline" :model="form">
      <a-row type="flex" justify="space-between">
         <a-form-item label="外部链接地址:">
            <a-input v-model="form.code" placeholder="请输入外部链接地址"/>
          </a-form-item>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      info: {}, // 对象信息
      form: {
        code: ''
      },
      moreText: '',
      identifier: '',
      linkUrl: '',
      newForm: {},
      paiName: '', // 接口名
      dataList: [],
      selectItem: '',
      replaceFields: { // 树替换属性
        children: 'child',
        title: 'name',
        key: 'id'
      }
    }
  },
  methods: {
    determine (e) {
      console.log('操你妈的')
      this.$emit('addCustomLink', { intent: 'open', linkUrl: this.form.code, resourceType: 'h5' })
      this.cancel()
      this.isShow = false
    },
    cancel () {
      console.log('呜呜呜呜呜')
      this.selectItem = ''
      this.form = {
        code: ''
      }
    }
  }
}
</script>

<style>
.selectModle .ant-modal-body{
  min-height: 300px;
}
</style>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
