<template>
  <a-modal :class="$style.container"
    v-model="visible"
      title="内容分页"
      width="960px"
      @cancel="handleCancel"
      :maskClosable="false"
  >

    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="名称:">
        <a-input v-model="form.name" placeholder="请输入" />
      </a-form-item>

      <a-row class="al-form__btn"
             type="flex"
             justify="end">
             <br/><br/>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-row>
    </a-form>

    <a-table :data-source="list"
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="false"
             :scroll="{ x: 960, y: 580 }">
      <span slot="status" slot-scope="text,record">
        {{ record.status===0?"未上线":record.status===1?"已上线":"不是0也不是1" }}
      </span>
      <span slot="iconUrl" slot-scope="text">
        <a-avatar shape="square" :src="text" />
      </span>
      <span slot="action" slot-scope="text,record">
        <a @click="addTo(record)">添加</a>
      </span>
    </a-table>

  </a-modal>
</template>

<script>

export default {
  components: { },
  data () {
    return {
      visible: false,
      iteminfo: '',
      doctorImag: '',
      isQueryLoading: false,
      form: {
        name: ''
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        { title: '操作', fixed: 'right', width: 150, dataIndex: 'action', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      showList: [],
      total: 0,
      list: [],
      titleList: []
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.reloadList()
      }
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      let apiStr = this.iteminfo.code === 'scene' ? 'cmsManageSceneSearch' : 'navigationAllTree'
      console.log(apiStr)
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const res = await this.$store.dispatch('http', {
        api: apiStr,
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      console.log(res, 'resssss')
      if (res.records) {
        this.list = res.records
        this.pagination.total = res.records.length
        return
      }
      this.list = res
      this.pagination.total = res.length
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      if (val.pageSize === Number(val.pageSizeOptions[0])) { // 页码切换
        this.pagination.current = val.current
        this.loadList()
      } else { // 每页显示条数切换
        this.pagination.current = 1
        this.pagination.pageSize = val.pageSize
        this.reloadList()
      }
    },
    // 添加商品
    async addTo (app) {
      app.resourceType = this.iteminfo.code
      // const res = await this.$store.dispatch('http', {
      //   api: ''
      // })
      app.cmsExtData = [
        {
          sort: 1,
          qty: 0,
          intro: '公司从02年开始连续获得“云茶杯”，“康乐杯”普洱茶质量评比五个金奖。03年开始连年获得国家茶叶质量监督检测中心的“无公害放心普洱茶”认证。05年被云南省采用制做普洱茶系列标准样。也是全省首批通过QS认证的五家普洱茶企业之一。勐库地处双江县北，紧靠北回归线北侧，最高海拔3233米，最低海拔1040米，气候温和、雨量充沛、冬无严寒、夏无酷暑、干湿季分明，是典型的亚热带立体气候。由于勐库具有适宜茶叶生长的气候和土壤条件，所以在海拔3228米的勐库大雪山原始森林中群落着5595亩的野生茶林，茶树树株最高达22米，基围粗3.25米，是全国群落面积最大、海拔最高的野生茶林，据有关专家推测，它们的生长年龄已经是2500年——3000年左右，如此大规模的古茶树林的发现，在世界茶叶历史上属实罕见，从而进一步证实了双江是世界茶树原产地之一。',
          name: '勐库戎氏',
          id: '5',
          iconUrl: 'http://bizbase.online/brand_logo_4.jpg'
        }
      ]
      this.$emit('addContentPage', app)
      this.handleCancel()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
