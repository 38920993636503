<template>
  <div :class="$style.title">
    <!-- 样式 -->
    <!-- {{objForm}} -->
    <!-- {{defaultComponentList['header'].componentStyleList}} -->
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">样式</div>
    <a-row>
        <a-radio-group v-model="objForm.codeStyle"  @change="headStyleChange">
          <a-radio-button style="width:100px;" v-for="(item,index) in defaultComponentList['carousel'].componentStyleList" :key="index" :value="item.codeStyle">
            <a-icon v-if="objForm.nameStyle===item.nameStyle" :class="$style.check_circle" type="check-circle" />
            <img :class="$style.head_style_img" :src="item.imageUrl" />
            <span style="position:absolute;display:block;width:100%;text-align:center;" class="$style.head_style_name">{{item.nameStyle}}</span>
          </a-radio-button>
        </a-radio-group>
    </a-row>
    </div>
    <!-- 间隔 -->
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">距离上边间隔</div>
      <div v-if="objForm">
        <a-input v-model="objForm.distance" @change="input1" placeholder="推荐20" />
      </div>
    </div>
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">添加图片</div>
      <div :class="$style.title__block__title" style="font-size:13px;">最多添加 10 个广告，鼠标拖拽调整广告顺序，建议宽度750像素,点击图片可修改图片</div>
    </div>
    <div v-if="objForm&&objForm.items.length!==0" :class="$style.title__imgList">
      <draggable
          v-bind="dragOptions"
          v-model="objForm.items"
          @end="onEnd"
          @change="toChange"
        >
        <!-- {{objForm.items}} -->
            <div  :class="$style.title__imgList__item"
              style="flex-direction: column;"
              v-for="(item,index) in objForm.items" :key="index">
              <div :class="$style.title__imgList__item" style="margin-bottom:0;padding:0;box-shadow:none;">
                <ImageManage v-model="item.imageUrl" @change="handleChange($event,index)" :width='750' :square="false" :size="1024*1024*2" :showWidth="true">
                </ImageManage>
                <!-- <img :class="$style.title__imgList__item__delete" v-if="index!==0" @click="deleteImg(index)"
                style="width:23px;height:23px;" src="~@/assets/images/icon_delete.png" alt=""> -->
                <!-- <a-upload name="file" list-type="picture-card" class="avatar-uploader" style="width:100px"
                  :show-upload-list="false" :action="$consts.uploadFileUrl"
                  :headers="$store.state.header" :before-upload="beforeUpload" @change="handleChange($event,index)">
                  <img v-if="item.imageUrl" :src="item.imageUrl" style="max-width: 80px;max-height: 80px;" alt="">
                  <div v-else>
                    <a-icon :type="item.status ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload> -->
                <div :class="$style.title__imgList__item__right">
                  <div :class="$style.title__imgList__item__right__top">
                    <span style="margin-right:10px;">标题</span>
                    <a-input v-model="item.name"
                    style="width: 180px"
                    :maxLength="10" @change="input1" placeholder="10个字以内，可不填" />
                  </div>
                  <!-- 信息回显 -->
                  <resourceInfo :showIndex="index" @fatherSelectItem="fatherSelectItem" :item="item"></resourceInfo>

                </div>
              </div>
              <div style="color:rgb(226, 220, 220);text-align:center;margin-top:10px">750*410像素，jpg、png格式，不超过300KB。</div>
              <div :class="$style.title__imgList__item__tip" v-if="imgStatus&&!item.imageUrl">请完成后再添加</div>
            </div>
      </draggable>
    </div>
    <div :class="$style.title__btn" v-if="objForm&&objForm.items.length<10" @click="addImg">添加图片</div>
    <!-- <div :class="$style.title__block">
      <br/>
      <div v-if="objForm">
        <span v-for="(value, key, index) in objForm.componentStyle" :key="index">
          <h1>{{ key }}</h1>
          <a-input v-model="objForm.componentStyle[key]" @change="addStyle" placeholder="" />
        </span>
      </div>
    </div> -->
    <div style="display:none">{{tolist}}</div>
  </div>
</template>

<script>
import resourceInfo from './resourceInfo'
import ImageManage from '@/components/imageManage/index.vue'
import Vuex from 'vuex'
const { mapState, mapActions } = Vuex.createNamespacedHelpers('allocation')
export default {
  props: ['defaultItem'],
  components: {
    resourceInfo, ImageManage
  },
  data () {
    return {
      headStyleValue: '1',
      dragOptions: { animation: 500 },
      imgStatus: false,
      objForm: '',
      // urlList: [],
      uploadStatus: true,
      zanshiFile: ''
    }
  },
  created () {
    // this.urlList = []
    // this.getComponentLinks()
    // this.tolist()
  },
  computed: {
    ...mapState([
      'endList',
      'selectIndex',
      'defaultComponentList'
    ]),
    // eslint-disable-next-line vue/return-in-computed-property
    tolist () {
      this.getLists()
    }

  },
  methods: {
    ...mapActions([
    ]),
    addStyle () {
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    headStyleChange (e) {
      this.defaultComponentList.carousel.componentStyleList.forEach((item, index) => {
        if (this.objForm.codeStyle === item.codeStyle) {
          // console.log(item, '样式item')
          this.objForm.nameStyle = item.nameStyle
          this.objForm.codeStyle = item.codeStyle
          this.objForm.idComponentStyle = item.id
        }
      })
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    toChange (e) {
      console.log(e)
      const oldIndex = e.moved.oldIndex
      const newIndex = e.moved.newIndex
      console.log('this.objForm', this.objForm)
      if (e.moved) {
        this.objForm.items[oldIndex].sort = oldIndex
        this.objForm.items[newIndex].sort = newIndex
      }
      // 冒泡排序
      var len = this.objForm.items.length - 1
      for (let j = 0; j < len; j++) {
        for (let i = 0; i < len - j; i++) {
          if (this.objForm.items[i].sort > this.objForm.items[i + 1].sort) {
            [this.objForm.items[i], this.objForm.items[i + 1]] = [this.objForm.items[i + 1], this.objForm.items[i]]
          }
        }
      }
      // 触发中间显示页面排序
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    onEnd (e) {
      // console.log('结束nav拖拽', e)
      // this.setList(this.$utils.clone(this.list2))
      // this.getHeight()
      // this.pageShow = true
    },
    ...mapActions([
      'setChangeList'
    ]),

    beforeUpload (file) {
      this.zanshiFile = file
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 < 300
      setTimeout(() => {
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', { type: 'error', message: '请上传图片格式为jpeg或者png的图片' })
          return false
        }
        if (!isLt2M) {
          this.$store.dispatch('showToast', { type: 'error', message: '图片大小不能超过300KB' })
        }
      }, 200)
      return isJpgOrPng && isLt2M
    },
    getIsBiLi (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const image = new Image()
        image.src = reader.result
        image.onload = () => {
          const w = image.width
          const h = image.height
          console.log('宽高：', w, h, w !== 750)
          if (w !== 750) {
            this.uploadStatus = true
          } else {
            this.uploadStatus = false
          }
        }
      }
    },
    async handleChange (file, index) {
      console.log(file, index, 'file,index')
      this.objForm.items[index].imageUrl = file
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    // 增加图片
    addImg () {
      const num = this.objForm.items.length
      // console.log('nummmmmmmmmmmmm', num)
      if (this.objForm.items.length === 0) {
        this.fun()
      } else {
        if (this.objForm.items[num - 1].imageUrl) {
          this.fun()
        } else {
          this.imgStatus = true
          this.$store.dispatch('showToast', { type: 'warning', message: '请完成后再添加' })
        }
      }
    },
    fun () {
      this.imgStatus = false
      let obj = this.$utils.clone(this.defaultItem.basic)
      obj.sort = this.objForm.items.length
      this.objForm.items.push(obj)
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    deleteImg (index) {
      this.$store.dispatch('showConfirm', {
        message: '确定要删除吗',
        yes: async _ => {
          this.objForm.items.splice(index, 1)
          this.setChangeList(this.objForm)
          this.$emit('changeList2')
        }
      })
    },
    getLists () {
      console.log(this.endList, 'endlist')
      console.log(this.endList[this.selectIndex], 'endlist')
      if (this.endList[this.selectIndex]) {
        this.objForm = this.$utils.clone(this.endList[this.selectIndex])
        console.log(this.objForm, '``````````')
      } else {
        this.objForm = ''
        console.log('??????????', this.objForm)
      }
    },
    input1 (e) {
      console.log(e.target.value)
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    fatherSelectItem (item) {
      this.objForm.items[item.showIndex] = { ...this.objForm.items[item.showIndex], ...item }
      console.log('组件里得到的数据', item)
      console.log('最终结果', this.objForm.items)
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    }
  },
  destroyed () {
    console.log('哈哈哈哈哈好')
  }
}
</script>

<style>
.avatar-uploader .ant-upload {
  width: 100px !important;
  height: 100px !important;
}
.ant-upload-select-picture-card{
  margin:0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-avatar{
  width:80px;
  height:80px;
}
.ant-radio-button-wrapper{
  height:auto;
  padding:0;
  margin:10px;
  border: none;
  margin-bottom: 20px;
}
</style>
<style lang="scss" module>
  .head_style_img{
    margin-left: -1px;
    width: 100px;
    height: 55px;
  }
  .head_style_name{
    position: absolute;
    color: red;
  }
  .check_circle{
    font-size:40px;
    font-weight:bold;
    color:red;
    position:absolute;
    top:8px;
    right:30px;
  }
  .title{
    width: 100%;
    padding: 20px 20px 0 30px;
    box-sizing: border-box;
    &__block{
      width: 100%;
      margin-bottom: 20px;
      &__title{
        margin-bottom: 20px;
      }
    }
    &__imgList{
      width: 100%;
      &__item{
        width: 100%;
        padding: 12px 8px;
        box-shadow:0px 0px 4px #999;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        position: relative;
        &__delete{
          position: absolute;
          top: -8px;
          right: -8px;
        }
        &__tip{
          position: absolute;
          bottom: -20px;
          left: 0;
          color: red;
        }
        &__right{
          width: 240px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-bottom: 6px;
          margin-left: 10px;
          &__top{
            width: 100%;
            display: flex;
            align-items: center;

          }
          &__bottom{
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    &__btn{
      width: 100%;
      padding: 10px;
      text-align: center;
      border: 1px solid #1890FF;
      cursor: pointer;
    }
  }
</style>
