<template>
  <div :class="$style.title">
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">样式</div>
      <!-- {{defaultComponentList['header'].componentStyleList}} -->
    <a-row>
        <a-radio-group v-model="objForm.codeStyle"   @change="headStyleChange">
          <a-radio-button style="width:100px;" v-for="(item,index) in defaultComponentList['separateLine'].componentStyleList" :key="index" :value="item.codeStyle">
            <a-icon v-if="objForm.nameStyle===item.nameStyle" :class="$style.check_circle" type="check-circle" />
            <img :class="$style.head_style_img" :src="item.imgUrl" />
            <span style="position:absolute;display:block;width:100%;text-align:center;" class="$style.head_style_name">{{item.nameStyle}}</span>
          </a-radio-button>
        </a-radio-group>
    </a-row>
    </div>
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">距离上边间隔</div>
      <div v-if="objForm">
        <a-input v-model="objForm.distance"  @change="input1" placeholder="推荐20" />
      </div>
    </div>
    <div :class="$style.title__block">
      <div :class="$style.title__block__title">自定义样式</div>
      <div v-if="objForm">
        <span v-for="(value, key, index) in objForm.componentStyle" :key="index">
          <span v-if="key==='bgColor'">
              <h1>{{ key }}</h1>
              <div style="border:1px solid #adadad; float:left;margin:0;padding:4px 5px 0 5px;">
                <colorPicker v-model="objForm.componentStyle[key]" v-on:change="headleChangeColor"></colorPicker>
              </div>
          </span>
        </span>
      </div>
    </div>
    <div :class="$style.title__block">
      <!-- <div :class="$style.title__block__title">链接</div> -->
      <urlPage v-if="false" :urlList="urlList" :fatherSelectItem="objForm?objForm.items[0]:''" @fatherSelectItem="fatherSelectItem"></urlPage>
    </div>
    <div style="display:none">{{tolist}}</div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import urlPage from './urlPage'
const { mapState, mapActions } = Vuex.createNamespacedHelpers('allocation')
export default {
  components: {
    urlPage
  },
  data () {
    return {
      objForm: {
      },
      urlList: []
    }
  },
  computed: {
    ...mapState([
      'endList',
      'selectIndex',
      'defaultComponentList'
    ]),
    // eslint-disable-next-line vue/return-in-computed-property
    tolist () {
      this.getLists()
    }
  },
  watch: {
    objForm (val) {
      // console.log(val, '1111')
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    }
  },
  created () {
    this.urlList = []
    this.getComponentLinks()
  },
  methods: {
    headStyleChange (e) {
      this.defaultComponentList.header.componentStyleList.forEach((item, index) => {
        if (this.objForm.codeStyle === item.codeStyle) {
          // console.log(item, '样式item')
          this.objForm.nameStyle = item.nameStyle
          this.objForm.codeStyle = item.codeStyle
          this.objForm.idComponentStyle = item.id
        }
      })
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    headleChangeColor () {
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    ...mapActions([
      'setChangeList'
    ]),
    getLists () {
      if (this.endList[this.selectIndex]) {
        this.objForm = this.$utils.clone(this.endList[this.selectIndex])
      } else {
        this.objForm = {
          cmsComponentStyle: {
            bgColor: '#eeeeee'
          }
        }
      }
      console.log('??????', this.objForm)
    },
    input1 (e) {
      console.log(e.target.value)
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    },
    async getComponentLinks () {
      const res = await this.$store.dispatch('http', {
        api: 'componentLinks'
      })
      console.log('链接列表', res)
      this.urlList = this.$utils.clone(res)
      this.urlList.push({ name: '自定义链接', code: '' })
    },
    fatherSelectItem (item) {
      this.objForm.items[0] = { ...this.objForm.items[0], ...item }
      console.log('最终结果', this.objForm.items[0])
      this.setChangeList(this.objForm)
      this.$emit('changeList2')
    }
  },
  destroyed () {
    console.log('哈哈哈哈哈好')
  }
}
</script>
<style>
.avatar-uploader .ant-upload {
  width: 100px !important;
  height: 100px !important;
}
.ant-upload-select-picture-card{
  margin:0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-avatar{
  width:80px;
  height:80px;
}
.ant-radio-button-wrapper{
  height:auto;
  padding:0;
  margin:10px;
  border: none;
  margin-bottom: 20px;
}
</style>
<style lang="scss" module>
  .head_style_img{
    margin-left: -1px;
    width: 100px;
    height: 55px;
  }
  .head_style_name{
    position: absolute;
    color: red;
  }
  .check_circle{
    font-size:40px;
    font-weight:bold;
    color:red;
    position:absolute;
    top:8px;
    right:30px;
  }
  .title{
    width: 100%;
    padding: 20px 20px 0 30px;
    box-sizing: border-box;
    &__block{
      width: 100%;
      margin-bottom: 20px;
      &__title{
        margin-bottom: 20px;
      }
    }
    &__imgList{
      width: 100%;
      &__item{
        width: 100%;
        padding: 12px 8px;
        box-shadow:0px 0px 4px #999;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        position: relative;
        &__delete{
          position: absolute;
          top: -8px;
          right: -8px;
        }
        &__tip{
          position: absolute;
          bottom: -20px;
          left: 0;
          color: red;
        }
        &__right{
          width: 240px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-bottom: 6px;
          margin-left: 10px;
          &__top{
            width: 100%;
            display: flex;
            align-items: center;

          }
          &__bottom{
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    &__btn{
      width: 100%;
      padding: 10px;
      text-align: center;
      border: 1px solid #1890FF;
      cursor: pointer;
    }
  }
</style>
