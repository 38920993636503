<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-12-15 13:44:24
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-18 10:17:51
-->
<template>
  <div v-if="compPageSettings.compNames.includes(item.codeComponent)" :style="{'padding-top':item.distance+'px'}">
    <div class="flex-row pd-lr-10" v-if="showPreview(item)">
      <div v-for="i in 2" :key="i" :class="'item-card card-style mg-bt-10 '+compPageSettings[item.codeComponent].className">
        <br/>
        {{compPageSettings[item.codeComponent].previewName}}{{i}}
        <br/>
        <br/>
      </div>
    </div>
    <div class="flex-row pd-lr-10" v-else>
      <div v-for="(ele,index) in item.items[0].cmsExtData" :key="index" :class="'item-card card-style mg-bt-10 '+compPageSettings[item.codeComponent].className">
        <img :src="ele.iconUrl?ele.iconUrl:ele.imgUrl?ele.imgUrl:ele.imageUrl?ele.imageUrl:'https://images.chamagujiao.net/2021/12/16/1471386638499278849.png'" />
        <div>{{ele.name?ele.name:ele.goodsName?ele.goodsName:ele.title?ele.title:'数据错误没有名称'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  components: {},
  data () {
    return {
      compPageSettings: {
        compNames: ['brandPage', 'goodsPage', 'contentPage'],
        brandPage: {
          previewName: '品牌',
          className: ''
        },
        goodsPage: {
          previewName: '商品',
          className: ''
        },
        contentPage: {
          previewName: '内容',
          className: 'content-item'
        }
      }
    }
  },
  methods: {
    showPreview (item) {
      console.log(item, '显示items')
      const { items } = { ...item }
      console.log(items, 'items')
      if (!items) return true
      if (items.length === 0) return true
      if (items.length === 1 && !items[0].cmsExtData) return true
      if (items.length === 1 && items[0].cmsExtData && items[0].cmsExtData.length === 0) return true
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row{
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  .item-card {
    width: 48%;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
    div {
      padding:10px;
    }
  }
  .content-item {
    width: 100%;
  }
}

.pd-lr-10{
  padding: 0 10px;
}
.mg-bt-10{
  margin-bottom: 10px;
}
.card-style {
  box-shadow: 0px 0px 4px #999
}
</style>
