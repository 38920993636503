<template>
  <div>
    <div style="width:100%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
      <div v-if="['xcx'].includes(item.resourceType)">跳转至：小程序</div>
      <div v-if="['xcx'].includes(item.resourceType)">APPID：{{item.identifier}}</div>
      <div v-if="['xcx','h5'].includes(item.resourceType)">地址：{{item.linkUrl}}</div>
      <div v-if="['xcx'].includes(item.resourceType)">更多：{{item.moreText}}</div>
      <div v-if="['sku'].includes(item.resourceType)">跳转至：商品-{{item.name}}</div>
      <div v-if="['brand'].includes(item.resourceType)">跳转至：品牌-{{item.name}}</div>
      <div v-if="['page','tabList'].includes(item.resourceType)">跳转至页面：{{item.identifier}}</div>
    </div>
    <div :class="$style.title__imgList__item__right__bottom">
      <span style="margin-right:10px;">资源</span>
      <urlPage :resourceBind="resourceBind" :urlList="urlList" :fatherSelectItem="item" :showIndex="showIndex" @fatherSelectItem="fatherSelectItem"></urlPage>
    </div>

  </div>
</template>

<script>
import urlPage from './urlPage'
export default {
  components: { urlPage },
  props: ['item', 'showIndex', 'faName', 'resourceBind'],
  data () {
    return {
      urlList: []
    }
  },
  created () {
    console.log(this.item, '传上过来的组件')
    this.getComponentLinks()
  },
  methods: {
    async getComponentLinks () {
      const res = await this.$store.dispatch('http', {
        api: 'resourceTypeList'
      })
      console.log('链接列表', res)

      if (this.resourceBind && this.resourceBind.length > 0) {
        let arr = []
        res.forEach(element => {
          if (this.resourceBind.includes(element.code)) {
            arr.push(element)
            console.log('去哪我要看')
          }
          this.urlList = arr
        })
        return
      }
      this.urlList = this.$utils.clone(res)
    },
    fatherSelectItem (item) {
      console.log(item)
      console.log(this.item)
      console.log(this.faName)
      if (this.faName === 'title') {
        // title组件
        this.item = { ...this.item, ...item }
        console.log(this.item)
      } else {
        this.item = item
      }
      this.$emit('fatherSelectItem', this.item)
    }
  }
}
</script>

<style lang="scss" module>
  .head_style_img{
    margin-left: -1px;
    width: 100px;
    height: 55px;
  }
  .head_style_name{
    position: absolute;
    color: red;
  }
  .check_circle{
    font-size:40px;
    font-weight:bold;
    color:red;
    position:absolute;
    top:8px;
    right:30px;
  }
  .title{
    width: 100%;
    padding: 20px 20px 0 30px;
    box-sizing: border-box;
    &__block{
      width: 100%;
      margin-bottom: 20px;
      &__title{
        margin-bottom: 20px;
      }
    }
    &__imgList{
      width: 100%;
      &__item{
        width: 100%;
        padding: 12px 8px;
        box-shadow:0px 0px 4px #999;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        position: relative;
        &__delete{
          position: absolute;
          top: -8px;
          right: -8px;
        }
        &__tip{
          position: absolute;
          bottom: -20px;
          left: 0;
          color: red;
        }
        &__right{
          width: 240px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-bottom: 6px;
          margin-left: 10px;
          &__top{
            width: 100%;
            display: flex;
            align-items: center;

          }
          &__bottom{
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    &__btn{
      width: 100%;
      padding: 10px;
      text-align: center;
      border: 1px solid #1890FF;
      cursor: pointer;
    }
  }
</style>
