<template>
  <a-modal :class="$style.container"
    v-model="visible"
      title="文章选择"
      :width="1500"
      @cancel="handleCancel"
      :maskClosable="false"
  >

    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="名称:">
        <a-input v-model="form.name" placeholder="请输入" />
      </a-form-item>

      <a-row class="al-form__btn"
             type="flex"
             justify="end">
             <br/><br/>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-row>
    </a-form>

    <a-table :data-source="list"
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination"
             :scroll="{ x: 1500, y: 580 }">
      <div slot="columnsName" slot-scope="text,record">
        <span v-for="item in record.navigationContentList" :key="item.idCmsNavigation">
            {{item.nameCmsNavigation}}
        </span>
      </div>
      <div slot="contentType" slot-scope="text,record">
          <div class="flex-row">
              <div style="display:flex; align-items:center; margin-right:20px; white-space: nowrap;">
                  {{
                      record.contentType==='article'?"文章":
                      record.contentType==='video'?"视频":
                      record.contentType==='audio'?"音频":
                      "无"
                  }}
              </div>
              <div>
                  <image style="max-height:50px;" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
              </div>
          </div>

      </div>
      <div slot="contentTitle" slot-scope="text,record">
          <strong>{{record.title}}</strong> <br/>
          {{record.titleSub}}
      </div>
      <div slot="viewStyle" slot-scope="text,record">
          {{
              record.viewStyle==='AS'?"文章-标题":
              record.viewStyle==='AS11'?"文章-标题+1图":
              record.viewStyle==='AS2'?"文章-标题+2图":
              record.viewStyle==='AS3'?"文章-标题+3图":
              record.viewStyle==='VS1'?"视频-标题+1图":
              "无"
          }}
      </div>
      <div slot="tags" slot-scope="text,record">
          <a-tag v-for="item in getTags(record.tags)" :key="item.index">
              {{item}}
          </a-tag>
      </div>
      <div slot="releaseStatus" slot-scope="text,record">
          {{
              record.releaseStatus===0?"未发布":
              record.releaseStatus===1?"已发布":
              record.releaseStatus===2?"已下线":
              "无"
          }}
      </div>
      <div slot="times" slot-scope="text,record">
          创建：{{record.gmtCreate}}<br/>
          修改：{{record.gmtModified}}<br/>
          发布：{{record.gmtRelease}}
      </div>
      <span slot="action" slot-scope="text,record">
        <a @click="addContent(record)">添加至内容列表</a>
      </span>
    </a-table>

  </a-modal>
</template>

<script>

export default {
  components: { },
  data () {
    return {
      visible: false,
      iteminfo: '',
      doctorImag: '',
      isQueryLoading: false,
      form: {
        name: ''
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: '栏目',
          dataIndex: 'columnsName',
          key: 'columnsName',
          scopedSlots: {
            customRender: 'columnsName'
          }
        },
        {
          title: '样式',
          dataIndex: 'viewStyle',
          key: 'viewStyle',
          scopedSlots: {
            customRender: 'viewStyle'
          }
        },
        // {
        //   title: '类型',
        //   dataIndex: 'contentType',
        //   key: 'contentType',
        //   scopedSlots: {
        //     customRender: 'contentType'
        //   }
        // },
        {
          title: '标题',
          dataIndex: 'contentTitle',
          key: 'contentTitle',
          width: 400,
          ellipsis: true,
          scopedSlots: {
            customRender: 'contentTitle'
          }
        },

        {
          title: '简介',
          dataIndex: 'intro',
          key: 'intro'
        },
        {
          title: '作者',
          dataIndex: 'author',
          key: 'author'
        },
        {
          title: '状态',
          dataIndex: 'releaseStatus',
          key: 'releaseStatus',
          scopedSlots: {
            customRender: 'releaseStatus'
          }
        },
        {
          title: '时间',
          dataIndex: 'times',
          key: 'times',
          width: 250,
          scopedSlots: {
            customRender: 'times'
          }
        },
        { title: '操作', fixed: 'right', width: 150, dataIndex: 'action', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      showList: [],
      total: 0,
      list: [],
      titleList: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      // this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        onsell: 1,
        idGouProducts: '8'
        // idGouProducts: '8,9,10'
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'searchContent',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      if (val.pageSize === Number(val.pageSizeOptions[0])) { // 页码切换
        this.pagination.current = val.current
        this.loadList()
      } else { // 每页显示条数切换
        this.pagination.current = 1
        this.pagination.pageSize = val.pageSize
        this.reloadList()
      }
    },
    // 添加商品
    addContent (app) {
      console.log(app, this.iteminfo)
      this.$emit('addContent', app)
      this.handleCancel()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
