<template>
  <div :class="$style.container">
    <div :class="$style.container__components">
      <draggable
        :class="$style.container__components__main"
        class="allocation_container__components__main"
        v-model="list1"
        draggable=".c-item"
        v-bind="dragOptions"
        @start="onStart" @end="onEnd"
         v-for="(itm,ids) in componentTypeArr" :key='ids'
        :options="{sort: false, group: {name: 'field', pull:'clone',put: false}}">
        <div
        @click=" e =>{collapseItems(e)}"
         slot="header"
        :class="itm.componentType"
        class="allocation_container__components__item_h1">
        <a-icon type="caret-down" />
          {{itm.componentName}}
        </div>
        <div v-for="(item,index) in list1" :key="index"
          @mousedown="toMousedown"
          class="item allocation__components__item" :class="{'c-item':mousedownStatus}">
          <span v-if="item.componentType===itm.componentType">
              <a-avatar style="width:60px;height:60px;" shape="square" :src="item.imageUrl?item.imageUrl:require('@/assets/images/component_icon_holder.png')"/>
              <div :title="item.nameComponent" style="width:100%;height:18px;white-space:nowrap;overflow:hidden;">{{item.nameComponent}}</div>
          </span>
        </div>
      </draggable>
    </div>
    <!-- 中间界面 -->
    <div :class="$style.Interface">
      <div :class="$style.Interface__btn" style="padding-top:10px">
        <a-button type="primary" :loading="loadingStatus" @click.stop="saveInterface" style="margin-right:20px">保存</a-button>
        <a-button type="primary" :loading="loadingStatus" @click.stop="pageShow=false">页面设置</a-button>
      </div>
      <div :class="$style.InterfaceBox">
        <draggable :class="$style.Interface__main" group="field"
          :list="list2" v-bind="dragOptions" @change="toChange" @end="onEnd1">
          <div v-for="(item,index) in list2"
            @click="clickMenu(item,index)"
            :class="$style.Interface__main__item" :key="index" class="item sss">

            <!-- 删除中间页面组件的小图标 -->
            <div v-if="index%2===0" :class="$style.Interface__main__item__tips" :style="{left:'-120px',top: Number(item.distance)+6+'px'}">
              <div :class="$style.Interface__main__item__tips__word">{{item.nameComponent}}</div>
              <div :class="$style.Interface__main__item__tips__btn" @click.stop="deleteComponets(index)">
                <img src="~@/assets/images/delete_component.png" style="width:20px;height:23px" alt="">
              </div>
            </div>
            <div v-else :class="$style.Interface__main__item__tips" :style="{top: Number(item.distance)+6+'px'}">
              <div :class="$style.Interface__main__item__tips__word">{{item.nameComponent}}</div>
              <div :class="$style.Interface__main__item__tips__btn" @click.stop="deleteComponets(index)">
                <img src="~@/assets/images/delete_component.png" style="width:20px;height:23px" alt="">
              </div>
            </div>

            <!-- 标题文本 -->
            <div :class="$style.Interface__main__item__box1" v-if="item.nameComponent === '标题栏组件'" :style="{'margin-top':item.distance+'px'}">
              <div :class="$style.Interface__main__item__box1__left">
                <div style="margin-right:20px">{{item.items[0] && item.items[0].name?item.items[0].name:'标题'}}</div>
                <div>{{item.items[0] && item.items[0].enName?item.items[0].enName:'描述'}}</div>
              </div>
              <div :class="$style.Interface__main__item__box1__right">
                <div style="margin-right:10px">{{item.items[0] && item.items[0].moreText?item.items[0].moreText:'更多内容'}}</div>
                <img style="width:7px;heigth:12px" src="~@/assets/images/right_arrow.png" alt="">
              </div>
            </div>

            <!-- 分页中间组件 -->
            <PageMiddle :item="item"></PageMiddle>
            <!-- 分隔行组件 -->

            <div v-if="item.nameComponent === '分隔行组件'" :style="{'padding-top':item.distance+'px'}">
               <!-- {{item.componentStyle}} -->
               <div v-for="(value,key,index) in item.componentStyle" :key="index">
                 <div v-if="key==='bgColor'">
                   <div :style="'width:100%;height:10px;background-color:'+(value===''?'#f8f8f8':value)"> </div>
                 </div>
               </div>
            </div>
            <!-- 头部组件 -->
            <div v-if="item.nameComponent === '头部组件' || item.codeComponent === 'carousel'" :style="{'margin-top':item.distance+'px'}">
              <!-- {{item.codeStyle}}--- {{item.nameStyle}} -->
              <img v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)"
              style="width:100%" src="https://img.yzcdn.cn/public_files/2019/03/05/2b60ed750a93a1bd6e17fc354c86fa78.png!large.webp" alt="">
              <!-- 如何数据库没有返回codeStyle -->
              <div>
                <a-carousel style="z-index:-100" autoplay>
                  <a-avatar style="width:100%;height:auto" shape="square" v-for="(imgItem,imgIndex) in item.items" :key="imgIndex" :src="imgItem.imageUrl" />
                </a-carousel>
              </div>
            </div>

            <!-- 顶部状态栏 -->
            <div v-if="item.nameComponent === '顶部状态栏'" :class="$style.Interface__main__item__box2">
              <!-- {{item.name}}33 -->
                <img style="width:12px;height:12px;" src="~@/assets/images/search_icon.png" alt="">
                <div style="margin-left:10px">搜索</div>
            </div>

            <!-- 导航组建 -->
            <div v-if="item.nameComponent === '导航组件'" :class="$style.Interface__main__item__box3" :style="{'margin-top':item.distance+'px'}">
              <div v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)"
                :class="$style.Interface__main__item__box3">
                <div :class="$style.Interface__main__item__box3__item"
                  :style="{'margin-bottom':d>3?'0px':'10px'}"
                  v-for="d in 8" :key="d">
                    <a-avatar style="width:36px;height:36px" shape="square" src="https://b.yzcdn.cn/public_files/2019/03/05/bd8a4dea63577aa7d5297c30dcd0d0bf.png" />
                    <div :class="$style.Interface__main__item__box3__item__words" style="font-size:12px;">导航{{d}}</div>
                    <div :class="$style.Interface__main__item__box3__item__words" style="font-size:12px;">英文{{d}}</div>
                </div>
              </div>
              <div v-else :class="$style.Interface__main__item__box3">
                <div :class="$style.Interface__main__item__box3__item"
                  :style="{'margin-bottom':daoIndex>3?'0px':'10px'}"
                  v-for="(daoItem,daoIndex) in  item.items" :key="daoIndex">
                    <a-avatar style="width:36px;height:36px" shape="square" :src="daoItem.imageUrl" />
                    <div :class="$style.Interface__main__item__box3__item__words" style="font-size:12px;">{{daoItem.name}}</div>
                    <div :class="$style.Interface__main__item__box3__item__words" style="font-size:12px;">{{daoItem.enName}}</div>
                </div>
              </div>

            </div>

            <!-- 两栏布局 -->
            <div v-if="item.nameComponent === '两栏布局组件'" :class="$style.Interface__main__item__box4" :style="{'margin-top':item.distance+'px'}">
              <div :class="$style.Interface__main__item__box4__block"
                v-if="item.items.length===2&&!item.items[0].imageUrl&&!item.items[1].imageUrl">
                <div :class="$style.Interface__main__item__box4__block__item">图片1</div>
                <div :class="$style.Interface__main__item__box4__block__item">图片2</div>
              </div>
              <div v-else :class="$style.Interface__main__item__box4__block">
                <div :class="$style.Interface__main__item__box4__block__item">
                  <a-avatar v-if="item.items[0].imageUrl" style="width:157px;height:77px;" shape="square" :src="item.items[0].imageUrl" />
                </div>
                <div :class="$style.Interface__main__item__box4__block__item">
                  <a-avatar v-if="item.items[1].imageUrl" style="width:157px;height:77px;" shape="square" :src="item.items[1].imageUrl" />
                </div>
              </div>
            </div>

            <!-- 栏目列表 -->
            <div :class="$style.Interface__main__item__box1" v-if="item.nameComponent === '顶部Tab栏' || item.codeComponent ==='tabList'" :style="{'margin-top':item.distance+'px'}">
              <div :class="$style.Interface__main__item__box4__block"
                v-if="item.items && item.items.length===1 && item.items[0].identifier ===''">
                <a-tabs>
                  <a-tab-pane tab="tab1">
                  </a-tab-pane>
                  <a-tab-pane tab="tab2">
                  </a-tab-pane>
                  <a-tab-pane tab="tab3">
                  </a-tab-pane>
                </a-tabs>
              </div>
              <a-tabs v-else :default-active-key="item.items[0].id" @change="changeTab">
                <a-tab-pane v-for="it in item.items" :key="it.identifier" :tab="it.name">
                </a-tab-pane>
              </a-tabs>
            </div>

            <!-- 品牌列表 -->
            <div v-if="item.codeComponent === 'brandList'" :style="{'margin-top':item.distance+'px'}" :class="$style.Interface__main__item__box9">
              <div v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)"
                :class="$style.Interface__main__item__box9__block">
                <div :class="$style.Interface__main__item__box9__block__item"
                v-for="d in 4" :key="d">品牌{{d}}</div>
              </div>
              <div v-else :class="$style.Interface__main__item__goods__oneblock">
                  <div  :class="$style.Interface__main__item__goods__block__item"  shape="square"
                    v-for="(imgItem,imgIndex) in item.items" :key="imgIndex" >
                        <div>
                          <img style="width:100%"
                        :src="imgItem.cmsExtData && imgItem.cmsExtData[0] && imgItem.cmsExtData[0].iconUrl?imgItem.cmsExtData[0].iconUrl:'https://images.chamagujiao.net/2021/12/16/1471386638499278849.png'" />
                        </div>
                        <div class="goods_item_name" style="text-align: center;padding:10px 0;">
                          {{imgItem &&imgItem.cmsExtData && imgItem.cmsExtData[0] && imgItem.cmsExtData[0].name?imgItem.cmsExtData[0].name:imgItem.name}}
                        </div>
                    </div>
              </div>
            </div>

            <!-- 文章列表 -->
            <div :class="$style.Interface__main__item__box1" v-if="item.nameComponent === '文章列表组件' || item.codeComponent ==='contentList'" :style="{'margin-top':item.distance+'px'}">
              <div v-if="(item.items && item.items.length===0)||(item.items && item.items.length===1&&!item.items[0].name)"
                :class="$style.Interface__main__item__box1__block">
                <div :class="$style.Interface__main__item__box1__block__item">文章</div>
              </div>
              <div v-else :class="$style.Interface__main__item__box1__block" >
                <template v-for="itm in item.items">
                  <div style="display:flex;margin-bottom:10px;" :key="itm.id">
                    <div style="width:100px;height:50px;overflow:hidden;">
                      <img
                        style="width:100%;"
                        :src="itm.cmsExtData && itm.cmsExtData[0] && itm.cmsExtData[0].imageUrl !==''?itm.cmsExtData[0].imageUrl:'https://images.chamagujiao.net/2021/12/16/1471386638499278849.png'"
                        alt="">
                    </div>
                    <div style="flex=1;text-align:left;padding-left:10px;">
                      <div>标题：{{itm.cmsExtData && itm.cmsExtData[0] && itm.cmsExtData[0].title !==''?itm.cmsExtData[0].title:'内容标题'}}</div>
                      <div>作者：{{itm.cmsExtData && itm.cmsExtData[0] && itm.cmsExtData[0].author !==''?itm.cmsExtData[0].author:''}}</div>

                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- 三栏布局 -->
            <div v-if="item.nameComponent === '三栏布局组件'" :class="$style.Interface__main__item__box5" :style="{'margin-top':item.distance+'px'}">
              <div :class="$style.Interface__main__item__box5__block"
                v-if="item.items.length===3&&!item.items[0].imageUrl&&!item.items[1].imageUrl&&!item.items[2].imageUrl">
                <div :class="$style.Interface__main__item__box5__block__left">图片1</div>
                <div :class="$style.Interface__main__item__box5__block__right">
                  <div :class="$style.Interface__main__item__box5__block__right__item">图片2</div>
                  <div :class="$style.Interface__main__item__box5__block__right__item" style="margin-top:16px;">图片3</div>
                </div>
              </div>
              <div v-else :class="$style.Interface__main__item__box5__block">
                <div :class="$style.Interface__main__item__box5__block__left">
                  <a-avatar v-if="item.items[0].imageUrl" style="width:157px;height:170px;" shape="square" :src="item.items[0].imageUrl" />
                </div>
                <div :class="$style.Interface__main__item__box5__block__right">
                  <div :class="$style.Interface__main__item__box5__block__right__item">
                    <a-avatar v-if="item.items[1].imageUrl" style="width:157px;height:77px;" shape="square" :src="item.items[1].imageUrl" />
                  </div>
                  <div :class="$style.Interface__main__item__box5__block__right__item" style="margin-top:16px;">
                    <a-avatar v-if="item.items[2].imageUrl" style="width:157px;height:77px;" shape="square" :src="item.items[2].imageUrl" />
                  </div>
                </div>
              </div>
            </div>

            <!-- 横向导航 -->
            <div v-if="item.nameComponent === '横向导航组件'" :class="$style.Interface__main__item__box6" :style="{'margin-top':item.distance+'px'}">
              <div :class="$style.Interface__main__item__box6__block"
                v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)">
                <div :class="$style.Interface__main__item__box6__block__item" v-for="a in 4" :key="a">图片{{a}}</div>
              </div>
              <div v-else :class="$style.Interface__main__item__box6__block">
                <div :class="$style.Interface__main__item__box6__block__item" v-for="(imgItem,imgIndex) in item.items" :key="imgIndex">
                  <a-avatar style="width:140px;height:67px;" shape="square" :src="imgItem.imageUrl" />
                </div>
              </div>
            </div>

            <!-- 单图组件 -->
            <div v-if="item.nameComponent === '单图组件'" :style="{'margin-top':item.distance+'px'}"
              :class="$style.Interface__main__item__box7">
              <div v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)"
              :class="$style.Interface__main__item__box7__item">图片</div>
              <div v-else style="width:100%;">
                <a-avatar style="width:100%;height:auto;" shape="square" :src="item.items[0].imageUrl" />
              </div>
            </div>
            <!-- 两栏列表 -->
            <div v-if="item.nameComponent === '两栏列表'" :style="{'margin-top':item.distance+'px'}" :class="$style.Interface__main__item__box9">
              <div v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)"
                :class="$style.Interface__main__item__box9__block">
                <div :class="$style.Interface__main__item__box9__block__item"
                v-for="d in 4" :key="d">图片{{d}}</div>
              </div>
              <div v-else :class="$style.Interface__main__item__box9__block">
                <a-avatar :class="$style.Interface__main__item__box9__block__item" shape="square"
                v-for="(imgItem,imgIndex) in item.items" :key="imgIndex" :src="imgItem.imageUrl" />
              </div>
            </div>

            <!-- 商品列表组件 -->
            <div v-if="item.codeComponent === 'goodsList'" :style="{'margin-top':item.distance+'px'}" :class="$style.Interface__main__item__box9">
              <div v-if="item.items.length===0||(item.items.length===1&&!item.items[0].imageUrl)"
                :class="$style.Interface__main__item__box9__block">
                <div :class="$style.Interface__main__item__box9__block__item"
                v-for="d in 4" :key="d">商品{{d}}</div>
              </div>
              <div v-else-if="item.codeStyle && item.codeStyle==='one'">
                <template v-for="imgItem in item.items">
                  <div :class="$style.Interface__main__item__goods__oneblock" :key="imgItem.id">
                    <div :class="$style.Interface__main__item__goods__oneblock__item">
                      <div style=" flex:1">
                        <img style="width:100%"
                        :src="imgItem && imgItem.cmsExtData && imgItem.cmsExtData[0] && imgItem.cmsExtData[0].imgUrl ?imgItem.cmsExtData[0].imgUrl:imgItem.imageUrl" />
                        </div>
                        <div style=" flex:1">
                            <div class="goods_item_name">
                              {{imgItem &&imgItem.cmsExtData && imgItem.cmsExtData[0] && imgItem.cmsExtData[0].goodsName?imgItem.cmsExtData[0].goodsName:imgItem.name}}
                            </div>
                            <div class="goods_item_price">
                              ￥{{imgItem &&imgItem.cmsExtData && imgItem.cmsExtData[0]?imgItem.cmsExtData[0].price:imgItem.price}}
                            </div>
                        </div>
                    </div>
                  </div>
                </template>
              </div>
              <div v-else>
                <template v-for="imgItem in item.items">
                  <div :class="$style.Interface__main__item__goods__block__item"  :key="imgItem.id">
                      <div style=" flex:1">
                        <img style="width:100%"
                        :src="imgItem && imgItem.cmsExtData && imgItem.cmsExtData[0] && imgItem.cmsExtData[0].imgUrl ?imgItem.cmsExtData[0].imgUrl:imgItem.imageUrl" />
                        </div>
                        <div style=" flex:1">
                            <div class="goods_item_name">
                              {{imgItem &&imgItem.cmsExtData && imgItem.cmsExtData[0] && imgItem.cmsExtData[0].goodsName?imgItem.cmsExtData[0].goodsName:imgItem.name}}
                            </div>
                            <div class="goods_item_price">
                              ￥{{imgItem &&imgItem.cmsExtData && imgItem.cmsExtData[0]?imgItem.cmsExtData[0].price:imgItem.price}}
                            </div>
                        </div>
                  </div>
                </template>
              </div>

            </div>
          </div>
        </draggable>
      </div>

    </div>
    <!-- 右侧设置 -->
    <div :class="$style.content" >
      <div :class="$style.content__main" v-show="mousedownStatus&&pageShow" v-if="selectItem">
        <div :class="$style.content__main__title">{{selectItem.nameComponent}}</div>
        <titles :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '标题栏组件'"></titles>
        <headers :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '头部组件' || selectItem.codeComponent === 'carousel'"></headers>
        <navigation :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '导航组件'"></navigation>
        <twoLayout :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '两栏布局组件'"></twoLayout>
        <threeLayout :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '三栏布局组件'"></threeLayout>
        <crosswiseNavigation :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '横向导航组件'"></crosswiseNavigation>
        <singleImg :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '单图组件'"></singleImg>
        <twoList :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '两栏列表'"></twoList>
        <brandList :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.codeComponent === 'brandList'"></brandList>
        <contentList :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.codeComponent === 'contentList'"></contentList>
        <tabList :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.codeComponent === 'tabList'"></tabList>
        <navigationList :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.codeComponent === 'navigationList'"></navigationList>
        <separateLine :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '分隔行组件'"></separateLine>
        <goodsList :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.nameComponent === '商品列表组件'"></goodsList>
        <!-- <goodsPageRight :defaultItem="componentSetting.items" @changeList2="changeList2" v-if="selectItem.codeComponent === 'goodsPage'"></goodsPageRight> -->

        <!-- 所有的分页组件都在这里 -->
        <PageRight :defaultItem="componentSetting.items" :nameComponent="selectItem.codeComponent" @changeList2="changeList2"></PageRight>
      </div>
      <div v-show="!pageShow" :class="$style.content__main">
        <div :class="$style.content__main__title">页面设置</div>
        <div :class="$style.content__main__pageSet">
          <div :class="$style.title__block">
            <div :class="$style.title__block__title">页面名称</div>
            <div>
              <a-input v-model="endForm.name" placeholder="请输入页面名称" />
            </div>
          </div>
          <div :class="$style.title__block">
            <div :class="$style.title__block__title">页面编号</div>
            <div>
              <!-- <a-select v-model="endForm.code"
                v-decorator="['select',
                    { rules: [{ required: true, message: '请选择页面类型' }] },
                ]"
                placeholder="请选择页面类型" style="width: 100%" @change="pageTypeChange"
              >
                <a-select-option v-for="(item,index) in cmsPageTypeList" :key="index" :value="item.code" :showValue="item.name">
                  {{item.name}}
                </a-select-option>
              </a-select> -->
              <a-input v-model="endForm.code" placeholder="请输入页面编号" />
            </div>
          </div>
          <div :class="$style.title__block">
            <div :class="$style.title__block__title">页面描述</div>
            <div>
              <a-input v-model="endForm.memo" placeholder="请输入页面描述" />
            </div>
          </div>
          <div :class="$style.title__block">
            <div :class="$style.title__block__title">是否设置为首页</div>
            <div>
              <a-radio-group v-model="endForm.homePage" :options="optionsList"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import titles from './components/rightComponents/title'
import headers from './components/rightComponents/header'
import navigation from './components/rightComponents/navigation'
import tabList from './components/rightComponents/tabList'
import twoLayout from './components/rightComponents/twoLayout'
import threeLayout from './components/rightComponents/threeLayout'
import crosswiseNavigation from './components/rightComponents/crosswiseNavigation'
import singleImg from './components/rightComponents/singleImg'
import twoList from './components/rightComponents/twoList'
import brandList from './components/rightComponents/brandList'
import contentList from './components/rightComponents/contentList'
import navigationList from './components/rightComponents/navigationList'
import separateLine from './components/rightComponents/separateLine'
import goodsList from './components/rightComponents/goodsList'

import PageMiddle from './components/pageMiddle'
import PageRight from './components/pageRight'

import Vuex from 'vuex'
const { mapState, mapActions } = Vuex.createNamespacedHelpers('allocation')

export default {
  components: {
    draggable,
    titles,
    headers,
    navigation,
    twoLayout,
    threeLayout,
    crosswiseNavigation,
    singleImg,
    twoList,
    brandList,
    separateLine,
    goodsList,
    contentList,
    navigationList,
    tabList,
    PageMiddle,
    PageRight
    // goodsPage,
    // goodsPageRight
  },
  data () {
    return {
      // 默认在这里配置组件的 个性数据
      componentSetting: {
        distance: {
          basic: 10,
          carousel: 0
        },
        componentStyle: {
          separateLine: {
            bgColor: '#f8f8f8'
          }
        },
        items: {
          basic: {
            name: '',
            enName: '',
            imageUrl: '',
            intent: 'none',
            resourceType: '',
            identifier: '',
            linkUrl: '',
            moreText: '',
            exData: 0,
            sort: 0,
            cmsExtData: []
          },
          twoLayout: {
            itemLength: 2
          },
          threeLayout: {
            itemLength: 3
          }
        }
      },

      componentTypeArr: [], // 有多少种类型的组件
      docSelect: 0,
      pageShow: false,
      mousedownStatus: false,
      id: '',
      loadingStatus: false,
      listHeight: 0, // list2的高度
      dragOptions: { animation: 500 },
      list1: [],
      list2: [],
      selectItem: '',
      endForm: {
        code: '',
        name: '',
        pageTypeCode: '',
        pageTypeName: '',
        homePage: 0,
        memo: ''
      },
      cmsPageTypeList: [],
      optionsList: [
        { label: '不设为首页', value: 0 },
        { label: '设为首页', value: 1 }
      ]
    }
  },
  watch: {
    'endForm.name' (a, b) {
      if (a) {
        this.mousedownStatus = true
      }
    }
  },
  computed: {
    ...mapState([
      'componentStyle',
      'itemsList', // 不同组件初如item的个数与参数
      'nameStileList',
      'endList',
      'selectIndex',
      'defaultComponentList'
    ])
  },
  created () {
    this.$store.commit('SET_CURR_ROUTE', {
      name: 'allocationList',
      path: '/allocation/list'
    })
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail(this.id)
    }
    // this.getComponentList()// 之前的组件列表
    this.getComponentLister()// 之后的
    this.getCmsPageTypeList()
  },
  methods: {
    ...mapActions([
      'setList',
      'setSelectIndex',
      'setDefaultComponentList'
    ]),
    changeTab (id) {
      console.log(id)
    },
    collapseItems (e) { // 点击菜单折叠左侧
      let classNames = e.path[0].getAttribute('class')
      //   点击的不是标题 return
      if (!classNames) {
        return
      }
      if (e.path[0].firstChild.style.transform === '') {
        e.path[0].firstChild.style.transform = 'rotateZ(-90deg)'
      } else {
        e.path[0].firstChild.style.transform = ''
      }
      if (e.path[1].getAttribute('open') === 'no') {
        e.path[1].setAttribute('open', 'yes')
        e.path[1].setAttribute('style', 'height:auto;')
      } else {
        e.path[1].setAttribute('open', 'no')
        e.path[1].setAttribute('style', 'height:50px;overflow:hidden')
      }
    },
    initData (arr) {
      let arrClone = this.$utils.clone(arr)
      arrClone.forEach((item) => {
        const { componentStyleList, codeComponent } = { ...item }
        delete item.id
        item.distance = this.getDistance(codeComponent)
        item.idComponentStyle = this.getComponentStyle(componentStyleList).id
        item.codeStyle = this.getComponentStyle(componentStyleList).code
        item.nameStyle = this.getComponentStyle(componentStyleList).name
        item.componentStyle = this.getComponentStyle(componentStyleList, codeComponent).componentStyle
        item.items = this.getComponentItems(codeComponent)
      })
      return arrClone
    },
    getComponentItems (codeComponent) {
      const items = this.componentSetting.items
      if (!items[codeComponent]) return [this.$utils.clone(items.basic)]
      if (items[codeComponent].itemLength === 0) return []
      if (items[codeComponent].itemLength > 0) {
        let arr = []
        for (let index = 0; index < items[codeComponent].itemLength; index++) {
          let obj = this.$utils.clone(items.basic)
          obj.sort = index
          arr.push(obj)
        }
        return arr
      }
      return [this.$utils.clone(items.basic)]
    },
    getComponentStyle (componentStyleList, codeComponent) {
      const styleSet = this.componentSetting.componentStyle
      if (styleSet[codeComponent]) {
        return { componentStyle: styleSet[codeComponent] }
      }
      if (!componentStyleList) return { id: undefined, code: undefined, name: undefined, componentStyle: undefined }
      if (componentStyleList.length > 0) {
        return {
          id: componentStyleList[0].id,
          code: componentStyleList[0].codeStyle,
          name: componentStyleList[0].nameStyle,
          componentStyle: componentStyleList[0]
        }
      }
      return { id: undefined, code: undefined, name: undefined, componentStyle: undefined }
    },
    getDistance (code) {
      const distanceSet = this.componentSetting.distance
      if (distanceSet[code] === 0) return 0
      if (distanceSet[code] > 0) return distanceSet[code]
      return distanceSet.basic
    },
    // 定义有多少个类型的组件  （例如公共组件,商城组件）
    setComponentType (arr) {
      const initData = this.initData(arr)
      arr = initData
      var obj = {}
      arr.forEach((item, index) => {
        item.value = item.value ? item.value : ''
        const { componentType, componentName } = item
        if (!obj[componentType]) {
          obj[componentType] = {
            componentType,
            componentName
          }
        }
      })
      this.componentTypeArr = obj

      this.list1 = arr
      console.log(this.componentTypeArr, '定义有多少个类型的组件')
    },
    // 新的组件列表
    async getComponentLister () {
      const res = await this.$store.dispatch('http', {
        // api: 'componentList' //旧的组件列表
        api: 'componentLister'
      })
      var resObj = {}
      res.forEach((item, index) => {
        console.log(item + index)
        resObj[item.codeComponent] = item
      })
      console.log(resObj, 'setDefaultComponentList')
      this.setDefaultComponentList(resObj) // 组件的样式，icon图
      this.setComponentType(res)// 组得到的组件分类
    },

    // 用户端首页组件列表
    async getCmsPageTypeList () {
      const res = await this.$store.dispatch('http', {
        api: 'cmsPageTypeList'
      })
      if (res) {
        const that = this
        res.map(item => {
          that.cmsPageTypeList.push({ code: item.code, name: item.name })
        })
      }
    },
    // 页面详情回显
    async getDetail (id) {
      let res = await this.$store.dispatch('http', {
        api: 'getManagePageDetail',
        query: {
          pageId: id
        }
      })
      console.log(res, '页面详情1111111111111')

      var len = res.pageComponentList.length - 1
      for (let j = 0; j < len; j++) {
        for (let i = 0; i < len - j; i++) {
          if (res.pageComponentList[i].sort > res.pageComponentList[i + 1].sort) {
            [res.pageComponentList[i], res.pageComponentList[i + 1]] = [res.pageComponentList[i + 1], res.pageComponentList[i]]
          }
        }
      }
      console.log('res', res)
      this.endForm = { ...this.endForm, ...res }
      console.log('endForm', this.endForm)
      setTimeout(() => {
        this.list2 = this.$utils.clone(res.pageComponentList)
        this.setList(this.$utils.clone(res.pageComponentList))
      }, 100)
      setTimeout(() => {
        this.getHeight()
      }, 500)
    },
    toChange (e) {
      if (e.added) {
        this.selectItem = e.added.element
        this.setSelectIndex({ index: e.added.newIndex })
        console.log('======', e.added.element)
        this.docSelect = 0
      }
    },
    toMousedown (e) {
      if (!this.mousedownStatus) {
        this.$store.dispatch('showToast', { type: 'warning', message: '页面名称您还未填写' })
      }
    },
    // 开始拖拽事件
    onStart (e) {
      console.log('开始拖拽事件', this.list2.length)
    },
    // 拖拽结束事件
    onEnd (e) {
      this.setList(this.$utils.clone(this.list2))
      this.getHeight()
      this.pageShow = true
    },
    onEnd1 (e) {
      console.log(e.newIndex)
      this.setList(this.$utils.clone(this.list2))
      this.selectItem = this.list2[e.newIndex]
      this.setSelectIndex({ index: e.newIndex })
    },
    // 获取列表高度
    getHeight () {
    //   var a = 0
    //   for (var i = 0; i < this.list2.length; i++) {
    //     // console.log(document.getElementsByClassName('sss')[i].offsetHeight)
    //     const b = document.getElementsByClassName('sss')[i].offsetHeight
    //     a = a + b
    //   }
    //   this.listHeight = a
    //   console.log('this.list2的高度', this.listHeight)
    },
    changeList2 () {
      console.log('changeList222222')
      this.list2 = this.$utils.clone(this.endList)
      console.log('end====>', this.list2)
      setTimeout(() => {
        this.getHeight()
      }, 200)
    },
    clickMenu (item, index) {
      console.log('选中的组件', item, index)
      this.docSelect = 0
      this.selectItem = item
      this.pageShow = true
      this.setSelectIndex({ index: index })
    },
    selectZu (index) {
      this.docSelect = index
    },
    // 删除某个已选择的组件
    deleteComponets (index) {
      this.list2.splice(index, 1)
      if (this.list2.length !== 0) {
        this.setList(this.$utils.clone(this.list2))
      } else {
        this.pageShow = false
        this.setList([])
      }
      this.getHeight()
      console.log(this.selectIndex)
      if (this.selectIndex === index) {
        this.selectItem = ''
      }
    },
    // 检验并保存配置
    saveInterface () {
      if (this.list2.length === 0) {
        this.$store.dispatch('showToast', { type: 'warning', message: '您还未配置' })
        return false
      }
      if (this.endForm.code === '') {
        this.$store.dispatch('showToast', { type: 'warning', message: '你还未填写页面编号' })
        return false
      }
      var flag = false
      this.list2.map(item => {
        console.log(item, 'list2item')
        if (!flag) {
          console.log(item, 'item.nameComponent')
          item.items.map((resItem, resIndex) => {
            if (!resItem.cmsExtData) {
              delete resItem.cmsExtData
            }
            if (!flag) {
              if (item.codeComponent === 'title') {
                if (!resItem.name) {
                  this.$store.dispatch('showToast',
                    { type: 'warning', message: '您' + item.nameComponent + '的标题内容' + '还未填写' })
                  flag = true
                  return false
                }
                // if (!resItem.enName) {
                //   this.$store.dispatch('showToast',
                //     { type: 'warning', message: '您' + item.nameComponent + '的描述内容' + '还未填写2222' })
                //   flag = true
                //   return false
                // }
              } else if (item.codeComponent === 'top') {
                // 如果是头部功能 不做校验
              } else if (item.codeComponent === 'header' || item.codeComponent === 'carouselA') {
                console.log(resItem, 'resItem')
                if (!resItem.imageUrl) {
                  const num = Number(resIndex) + 1
                  this.$store.dispatch('showToast',
                    { type: 'warning', message: '您' + item.nameComponent + '的第' + num + '个图片未上传' })
                  flag = true
                  return false
                }
              } else if (item.codeComponent === 'goodsList') {
                console.log('=========', resItem)
                if (!resItem.name) {
                  const num = Number(resIndex) + 1
                  this.$store.dispatch('showToast',
                    { type: 'warning', message: '您' + item.nameComponent + '的第' + num + '个商品未指定' })
                  flag = true
                  return false
                }
                // resItem.cmsExtData = this.$utils.clone(JSON.stringify(resItem.cmsExtData))
              } else {
                if (item.codeComponent === 'navigation') {
                  // console.log(resItem, 'SM万一')
                  // if (!resItem.imageUrl) {
                  //   const num = Number(resIndex) + 1
                  //   this.$store.dispatch('showToast',
                  //     { type: 'warning', message: '您' + item.nameComponent + '的第' + num + '个广告的图片' + '还未上传' })
                  //   flag = true
                  //   return false
                  // }
                  // if (!resItem.name) {
                  //   const num = Number(resIndex) + 1
                  //   this.$store.dispatch('showToast',
                  //     { type: 'warning', message: '您' + item.nameComponent + '的第' + num + '个广告的中文标题' + '还未填写' })
                  //   flag = true
                  //   return false
                  // }
                  // // if (!resItem.enName) {
                  // //   const num = Number(resIndex) + 1
                  // //   this.$store.dispatch('showToast',
                  // //     { type: 'warning', message: '您' + item.nameComponent + '的第' + num + '个广告的英文标题' + '还未填写' })
                  // //   flag = true
                  // //   return false
                  // // }
                  // if (!resItem.itemType) {
                  //   const num = Number(resIndex) + 1
                  //   this.$store.dispatch('showToast',
                  //     { type: 'warning', message: '您' + item.nameComponent + '的第' + num + '个广告的链接' + '还未填写' })
                  //   flag = true
                  //   return false
                  // }
                }
              }
            }
          })
        }
      })
      if (!flag) {
        // 在此处清除 数所库中的 imageUrl icon的图标， 无效数据
        this.list2.forEach(item => {
          item.imageUrl = ''
          if (item.componentStyle && item.componentStyle.imageUrl) {
            item.componentStyle.imageUrl = ''
          }
          if (item.componentStyleList && item.componentStyleList.length > 0) {
            item.componentStyleList.forEach(item2 => {
              item2.imageUrl = ''
            })
          }
        })
        console.log('校验完成', this.list2)

        this.apiFun()
        // this.loadingStatus = true
      }
    },
    async apiFun () {
      this.endForm.pageComponentList = this.list2
      const obj = this.endForm
      const res = await this.$store.dispatch('http', {
        api: this.id ? 'managePageEdit' : 'managePageAdd',
        params: obj,
        success: () => {
          this.$store.dispatch('showToast', { message: (this.id ? '编辑' : '新增') + '成功~' })

          setTimeout(() => {
            console.log('页面详情', this.list2)
            this.setList(this.$utils.clone(this.list2))
          }, 100)
          // this.$router.replace('/allocation/list')
        }
      })
      if (res && !this.id) {
        this.$router.push({ name: 'allocation', query: { id: res } })
      }
    },
    pageTypeChange (value) {
      if (value !== '') {
        this.cmsPageTypeList.map(item => {
          if (item.code === value) {
            this.endForm.pageTypeName = item.name
            return true
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.goods_item_name {
  width:100%;
  padding:10px 0 0 10px;
  text-align:left;
}
.goods_item_price {
  width:100%;
  padding:0 0 10px 10px;
  text-align:left;
  font-size: 12px;
  color:red;
  font-weight: bold;
}
.allocation_container__components__item_h1{
  cursor: pointer;
  width: 100%;
  height:30px;
  margin-top:20px;
}
.allocation_container__components__main{
  padding-left:10px;
  display:flex;
  flex-wrap: wrap;
  background:balck;
}
.allocation__components__item{
  display:flex;
  flex-direction: column;
  align-items: center;
  // width: 84px;
  padding:0px;
  text-align:center;
  box-sizing: border-box;
  font-size: 12px;
  &:hover{
    background: #f1f1f1;
  }
}
.allocation__components__item>span{
  width:84px;
  display:block;
  padding:12px;
}
.allocation__components__item img{
  margin-left: 5px;
  width:50px;
  height:50px;
}
</style>
<style lang="scss" module>
@import './styles/index.scss';
</style>
