<template>
  <a-modal :class="$style.container"
    v-model="visible"
      title="商品选择"
      width="960px"
      @cancel="handleCancel"
      :maskClosable="false"
  >
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="名称:">
        <a-input v-model="form.name" placeholder="请输入" />
      </a-form-item>

      <a-row class="al-form__btn"
             type="flex"
             justify="end">
             <br/><br/>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-row>
    </a-form>

    <a-table :data-source="list"
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination"
             :scroll="{ x: 800, y: 580 }">
      <div slot="action1"
           slot-scope="scope">
        <div>{{scope.idGoGoods}}</div>
      </div>
      <div slot="action2"
           slot-scope="scope">
        <div>{{scope.goodsId}}</div>
      </div>
      <div slot="action3"
           slot-scope="scope">
        <a  @click="zoom(scope.img_url)">
            <a-avatar shape="square"
                  style="width:80px;height:80px;"
                  :src="scope.imgUrl" />
        </a>
      </div>
      <div slot="action4"
           slot-scope="scope">
        <div>{{scope.goodsName}}</div>
      </div>

      <div slot="action5"
           slot-scope="scope">
        <div>{{scope.goodsTypeName}}</div>
      </div>

      <!-- 被折叠的数据 -->
      <!-- <div slot="expandedRowRender"
         slot-scope="scope"
         style="margin: 0">
        <div>创建时间：{{scope.gmtCreate}}</div>
        <div>价格(元)：{{scope.price}}</div>
        <div>库存：{{scope.stock}}</div>
        <div>禁售原因：{{scope.stopResason}}</div>
        <div>审核失败原因：{{scope.auditReason}}</div>
      </div> -->
      <span slot="action"
            slot-scope="app">
        <a @click="addGoods(app)"><a-spin size="small" v-if="app.isLoading" />添加至商品列表</a>
      </span>
    </a-table>

  </a-modal>
</template>

<script>
export default {
  components: { },
  data () {
    return {
      visible: false,
      index: '',
      doctorImag: '',
      isQueryLoading: false,
      form: {
        name: ''
      },
      columns: [
        // { title: 'SPU', width: 80, key: 'idGoGoods', scopedSlots: { customRender: 'action1' } },
        // { title: 'SKU', width: 80, key: 'id', scopedSlots: { customRender: 'action2' } },
        { title: '图片', width: 50, key: 'imgUrl', scopedSlots: { customRender: 'action3' } },
        { title: '商品名称', width: 150, key: 'name', scopedSlots: { customRender: 'action4' } },
        { title: '分类', width: 80, key: 'nameGoyGoods', scopedSlots: { customRender: 'action5' } },
        { title: '操作', key: 'operation', fixed: 'right', width: 150, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      showList: [],
      total: 0,
      list: [],
      titleList: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      // this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        onsell: 1,
        idGouProducts: '8'
        // idGouProducts: '8,9,10'
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'productPageManage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    },
    // 添加商品
    addGoods (app) {
      console.log(app)
      this.$emit('addGoods', app, this.index)
      this.handleCancel()
    }
  }
}
</script>
<style lang="scss">
.ant-table-pagination.ant-pagination{
    width:100% !important;
}
</style>
<style lang="scss" module>
.container {
  width: auto;
}
</style>
